<template>
  <main id="Basic">
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.System/Basic') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs variant="pills" :vertical="{ navs: 'col-lg-2 col-md-3 col-sm-12 col-12 mb-4', content: 'col-lg-10 col-md-9 col-sm-12 col-12' }">
            <CTab v-if="Features.includes('Info')" :title="$t('System/Basic.Info')">
              <h5 class="d-flex justify-content-between">
                  {{ $t('System/Basic.Info') }}
                  <div class="card-header-actions d-flex">
                      <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
                  </div>
              </h5>
              <hr>
              <CRow>
                  <template v-if="NowChooseLanguage === 'default'">
                      <CCol lg="6">
                          <CInput horizontal v-model="BasicData.Info.Title" :label="$t('System/Basic.Title')" />
                          <CInput horizontal v-model="BasicData.Info.SubTitle" :label="$t('System/Basic.SubTitle')"  />
                          <CInput horizontal v-model="BasicData.Info.OGDescription" :label="$t('System/Basic.OG:Description')"  />
                      </CCol>
                      <CCol lg="6">
                          <CInput horizontal v-model="BasicData.Info.Description" :label="$t('System/Basic.Description')" />
                          <CInput horizontal v-model="BasicData.Info.Keywords" :label="$t('System/Basic.Keywords')" />
                          <CInput
                            type="text"
                            :label="$t('System/Basic.OG:Image')"
                            v-model="BasicData.Info.OGImage"
                            horizontal
                          >
                            <template #append>
                              <CButton type="button" color="dark" @click="OpenMediaStore('Info', false, false, 'OGImage')">{{ $t('Global.Browse') }}</CButton>
                            </template>
                          </CInput>
                      </CCol>
                  </template>
                  <template v-else>
                      <CCol lg="6">
                          <CInput horizontal v-model="BasicData.Info.LanguageData[NowChooseLanguage].Title" :label="$t('System/Basic.Title')" />
                          <CInput horizontal v-model="BasicData.Info.LanguageData[NowChooseLanguage].SubTitle" :label="$t('System/Basic.SubTitle')"  />
                          <CInput horizontal v-model="BasicData.Info.LanguageData[NowChooseLanguage].OGDescription" :label="$t('System/Basic.OG:Description')"  />
                      </CCol>
                      <CCol lg="6">
                          <CInput horizontal v-model="BasicData.Info.LanguageData[NowChooseLanguage].Description" :label="$t('System/Basic.Description')" />
                          <CInput horizontal v-model="BasicData.Info.LanguageData[NowChooseLanguage].Keywords" :label="$t('System/Basic.Keywords')" />
                          <CInput
                            type="text"
                            :label="$t('System/Basic.OG:Image')"
                            v-model="BasicData.Info.LanguageData[NowChooseLanguage].OGImage"
                            horizontal
                          >
                            <template #append>
                              <CButton type="button" color="dark" @click="OpenMediaStore('Info', false, false, 'OGImage')">{{ $t('Global.Browse') }}</CButton>
                            </template>
                          </CInput>
                      </CCol>
                  </template>
                  <CCol lg="6">
                      <CInput horizontal v-model="BasicData.Info.GoogleSearchConsoleCode" :label="$t('System/Basic.GoogleSearchConsoleCode')" :description="$t('System/Basic.GoogleSearchConsoleCodeInfo')" />
                      <CInput horizontal v-model="BasicData.Info.FacebookAppID" :label="$t('System/Basic.FacebookAppID')" :description="$t('System/Basic.FacebookAppIDInfo')" />
                      <CInput horizontal v-model="BasicData.Info.Domain" :label="$t('System/Basic.Domain')" :description="$t('System/Basic.DomainInfo')" />
                  </CCol>
                  <CCol lg="6">
                      <CInput horizontal v-model="BasicData.Info.LineLiffID" :label="$t('System/Basic.LineLiffID')" :description="$t('System/Basic.LineLiffIDInfo')" />
                      <CInput horizontal v-model="BasicData.Info.BingConsoleCode" :label="$t('System/Basic.BingConsoleCode')" :description="$t('System/Basic.BingConsoleCodeInfo')" />
                      <CInput horizontal v-model="BasicData.Info.SystemEmail" :label="$t('System/Basic.SystemEmail')" :description="$t('System/Basic.SystemEmailInfo')" />
                  </CCol>
                  <CCol lg="6">
                      <CRow class="mb-3">
                          <CCol tag="label" sm="3" class="col-form-label">
                              {{ $t('System/Basic.Logo') }}
                          </CCol>
                          <CCol xl="4" lg="6" class="px-1 mb-2">
                              <template v-if="NowChooseLanguage === 'default'">
                                  <div v-if="BasicData.Info.Logo" class="position-relative CarouselItem">
                                      <img v-lazy="BasicData.Info.Logo" class="img-fluid" />
                                      <div class="CarouselTools">
                                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Logo')">
                                              <CIcon name="cil-pencil" />
                                          </CButton>
                                      </div>
                                  </div>
                                  <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                                      <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                                      <div class="CarouselTools">
                                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Logo')">
                                              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                          </CButton>
                                      </div>
                                  </div>
                              </template>
                              <template v-else>
                                  <div v-if="BasicData.Info.LanguageData[NowChooseLanguage].Logo" class="position-relative CarouselItem">
                                      <img v-lazy="BasicData.Info.LanguageData[NowChooseLanguage].Logo" class="img-fluid" />
                                      <div class="CarouselTools">
                                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Logo')">
                                              <CIcon name="cil-pencil" />
                                          </CButton>
                                      </div>
                                  </div>
                                  <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                                      <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                                      <div class="CarouselTools">
                                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Logo')">
                                              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                          </CButton>
                                      </div>
                                  </div>
                              </template>
                          </CCol>
                      </CRow>
                  </CCol>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <CCol tag="label" sm="3" class="col-form-label">
                      {{ $t('System/Basic.LogoAlt') }}
                    </CCol>
                    <CCol xl="4" lg="6" class="px-1 mb-2">
                      <template v-if="NowChooseLanguage === 'default'">
                        <div v-if="BasicData.Info.LogoAlt" class="position-relative CarouselItem">
                          <img v-lazy="BasicData.Info.LogoAlt" class="img-fluid" />
                          <div class="CarouselTools">
                            <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'LogoAlt')">
                              <CIcon name="cil-pencil" />
                            </CButton>
                          </div>
                        </div>
                        <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                          <div class="CarouselTools">
                            <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'LogoAlt')">
                              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                            </CButton>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div v-if="BasicData.Info.LanguageData[NowChooseLanguage].LogoAlt" class="position-relative CarouselItem">
                          <img v-lazy="BasicData.Info.LanguageData[NowChooseLanguage].LogoAlt" class="img-fluid" />
                          <div class="CarouselTools">
                            <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'LogoAlt')">
                              <CIcon name="cil-pencil" />
                            </CButton>
                          </div>
                        </div>
                        <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                          <div class="CarouselTools">
                            <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'LogoAlt')">
                              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                            </CButton>
                          </div>
                        </div>
                      </template>
                    </CCol>
                  </CRow>
                </CCol>
                  <CCol lg="6">
                      <CRow class="mb-3">
                          <CCol tag="label" sm="3" class="col-form-label">
                              {{ $t('System/Basic.Favicon') }}
                          </CCol>
                          <CCol xl="4" lg="6" class="px-1 mb-2">
                            <div class="favicon">
                              <template v-if="NowChooseLanguage === 'default'">
                                <div v-if="BasicData.Info.Favicon" class="position-relative CarouselItem">
                                  <img v-lazy="BasicData.Info.Favicon" class="img-fluid" />
                                  <div class="CarouselTools">
                                    <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Favicon')">
                                      <CIcon name="cil-pencil" />
                                    </CButton>
                                  </div>
                                </div>
                                <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                                  <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                                  <div class="CarouselTools">
                                    <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Favicon')">
                                      <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                    </CButton>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div v-if="BasicData.Info.LanguageData[NowChooseLanguage].Favicon" class="position-relative CarouselItem">
                                  <img v-lazy="BasicData.Info.LanguageData[NowChooseLanguage].Favicon" class="img-fluid" />
                                  <div class="CarouselTools">
                                    <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Favicon')">
                                      <CIcon name="cil-pencil" />
                                    </CButton>
                                  </div>
                                </div>
                                <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                                  <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                                  <div class="CarouselTools">
                                    <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Info', false, false, 'Favicon')">
                                      <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                    </CButton>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </CCol>
                      </CRow>
                  </CCol>
              </CRow>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData('Info')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('Social')" :title="$t('System/Basic.Social')">
              <h5>{{ $t('System/Basic.Social') }}</h5>
              <hr>
              <template v-for="(Data, Index) in BasicData.Social">
                <CInput type="text" :key="Index" v-model="Data.Link">
                  <template #prepend>
                    <CDropdown color="light">
                      <template #toggler-content>
                        <CIcon :name="SocialOptions[Data.Name]" class="mr-2"></CIcon> {{ Data.Name }}
                      </template>
                      <CDropdownItem v-for="(Icon, Social) in FilterSocialOptions" :key="Social" @click="(Data.Name = Social)">
                        <CIcon :name="Icon" class="mr-2"></CIcon>
                        <span>{{ Social }}</span>
                      </CDropdownItem>
                    </CDropdown>
                  </template>
                  <template #append>
                    <CButton type="button" color="danger" @click="RemoveData('Social', Index)">
                      <CIcon name="cil-trash"></CIcon>
                    </CButton>
                  </template>
                </CInput>
              </template>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton v-if="Object.keys(FilterSocialOptions).length > 0" @click="AddData('Social')" color="info">{{ $t('Global.Add') + $t('System/Basic.Social') }}</CButton>
                <CButton @click="SetData('Social')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('Contact')" :title="$t('System/Basic.Contact')">
              <h5 class="d-flex justify-content-between">
                  {{ $t('System/Basic.Contact') }}
                  <div class="card-header-actions d-flex">
                      <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
                  </div>
              </h5>
              <hr>
              <CRow>
                <CCol lg="6">
                    <template v-if="NowChooseLanguage === 'default'">
                        <CInput horizontal v-model="BasicData.Contact.CompanyName" :label="$t('System/Basic.CompanyName')" />
                        <CInput horizontal v-model="BasicData.Contact.Address" :label="$t('System/Basic.Address')" />
                        <CInput horizontal v-model="BasicData.Contact.Tel" :label="$t('System/Basic.Phone')" />
                        <CInput horizontal v-model="BasicData.Contact.Mobile" :label="$t('System/Basic.Mobile')" />
                    </template>
                    <template v-else>
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].CompanyName" :label="$t('System/Basic.CompanyName')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].Address" :label="$t('System/Basic.Address')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].Tel" :label="$t('System/Basic.Phone')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].Mobile" :label="$t('System/Basic.Mobile')" />
                    </template>
                </CCol>
                <CCol lg="6">
                    <template v-if="NowChooseLanguage === 'default'">
                        <CInput horizontal v-model="BasicData.Contact.Fax" :label="$t('System/Basic.Fax')" />
                        <CInput horizontal v-model="BasicData.Contact.Email" :label="$t('System/Basic.Email')" />
                        <CInput horizontal v-model="BasicData.Contact.ServiceTime" :label="$t('System/Basic.ServiceTime')" />
                        <CInput horizontal v-model="BasicData.Contact.TaxNumber" :label="$t('System/Basic.TaxNumber')" />
                        <CInput horizontal v-model="BasicData.Contact.FDANumber" :label="$t('System/Basic.FDANumber')" />
                    </template>
                    <template v-else>
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].Fax" :label="$t('System/Basic.Fax')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].Email" :label="$t('System/Basic.Email')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].ServiceTime" :label="$t('System/Basic.ServiceTime')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].TaxNumber" :label="$t('System/Basic.TaxNumber')" />
                        <CInput horizontal v-model="BasicData.Contact.LanguageData[NowChooseLanguage].FDANumber" :label="$t('System/Basic.FDANumber')" />
                    </template>
                </CCol>
              </CRow>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData('Contact')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('ServiceLocation')" :title="$t('System/Basic.ServiceLocation')">
              <h5 class="d-flex justify-content-between">
                  {{ $t('System/Basic.ServiceLocation') }}
                  <div class="card-header-actions d-flex">
                      <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
                  </div>
              </h5>
              <hr>
              <CCard v-for="(Item, Key) in BasicData.ServiceLocation" :key="Key">
                <CRow class="card-header bg-dark text-white p-0" :gutters="false">
                  <CCol lg="11" sm="10" col="10">
                    <CButton
                        block
                        color="link"
                        class="text-left shadow-none px-3 py-2 text-white"
                        @click="(Accordion = (Accordion === Key ? false : Key))"
                    >
                      {{ Item.Name }}
                    </CButton>
                  </CCol>
                  <CCol lg="1" sm="2" col="2">
                    <CButton
                        block
                        color="dark"
                        class="text-left shadow-none px-2 text-center"
                        @click="RemoveData('ServiceLocation', Key)"
                    >
                      <CIcon name="cil-trash" />
                    </CButton>
                  </CCol>
                </CRow>
                <CCollapse :show="Accordion === Key">
                  <CCardBody>
                    <CRow>
                      <CCol md="6" col="12">
                        <template v-if="NowChooseLanguage === 'default'">
                          <CInput
                              type="text"
                              :label="$t('Contact.LocationName')"
                              v-model="Item.Name"
                          />
                          <CInput
                              :label="$t('Contact.Address')"
                              v-model="Item.Address"
                              type="text"/>
                          <CInput
                              type="text"
                              :label="$t('Contact.TEL')"
                              v-model="Item.TEL"
                          />
                          <CInput
                              type="text"
                              placeholder="https://"
                              :label="$t('Contact.WebURL')"
                              v-model="Item.WebURL"
                          />
                        </template>
                        <template v-else>
                          <CInput
                              type="text"
                              :label="$t('Contact.LocationName')"
                              v-model="Item.LanguageData[NowChooseLanguage].Name"
                          />
                          <CInput
                              :label="$t('Contact.Address')"
                              v-model="Item.LanguageData[NowChooseLanguage].Address"
                              type="text"/>
                          <CInput
                              type="text"
                              :label="$t('Contact.TEL')"
                              v-model="Item.LanguageData[NowChooseLanguage].TEL"
                          />
                          <CInput
                              type="text"
                              placeholder="https://"
                              :label="$t('Contact.WebURL')"
                              v-model="Item.LanguageData[NowChooseLanguage].WebURL"
                          />
                        </template>
                      </CCol>
                      <CCol md="6" col="12">
                        <template v-if="NowChooseLanguage === 'default'">
                          <CInput
                              type="text"
                              :label="$t('Contact.FAX')"
                              v-model="Item.FAX"
                          />
                          <CInput
                              :label="$t('Contact.BusinessHours')"
                              v-model="Item.BusinessHours"
                              type="text"/>
                          <CInput
                              type="text"
                              :label="$t('Contact.GoogleMapURL')"
                              v-model="Item.GoogleMapURL"
                          />
                          <CInput
                              type="text"
                              :label="$t('Contact.Cover')"
                              v-model="Item.Cover"
                          >
                            <template #append>
                              <CButton type="button" color="dark" @click="OpenMediaStore('ServiceLocation', false, Key, 'Cover')">{{ $t('Global.Browse') }}</CButton>
                            </template>
                          </CInput>
                        </template>
                        <template v-else>
                          <CInput
                              type="text"
                              :label="$t('Contact.FAX')"
                              v-model="Item.LanguageData[NowChooseLanguage].FAX"
                          />
                          <CInput
                              :label="$t('Contact.BusinessHours')"
                              v-model="Item.LanguageData[NowChooseLanguage].BusinessHours"
                              type="text"/>
                          <CInput
                              type="text"
                              :label="$t('Contact.GoogleMapURL')"
                              v-model="Item.LanguageData[NowChooseLanguage].GoogleMapURL"
                          />
                          <CInput
                              type="text"
                              :label="$t('Contact.Cover')"
                              v-model="Item.LanguageData[NowChooseLanguage].Cover"
                          >
                            <template #append>
                              <CButton type="button" color="dark" @click="OpenMediaStore('ServiceLocation', false, Key, 'Cover')">{{ $t('Global.Browse') }}</CButton>
                            </template>
                          </CInput>
                        </template>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              </CCard>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton @click="AddData('ServiceLocation')" color="info">{{ $t('System/Themes.AddServiceLocation') }}</CButton>
                <CButton @click="SetData('ServiceLocation')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab v-if="Features.includes('Location')" :title="$t('System/Basic.Location')">
              <h5>{{ $t('System/Basic.Location') }}</h5>
              <hr class="mb-2">
              <div class="mb-2">
                  <CButton color="white" shape="pill" class="mr-2" @click="LocationFilterModel = true;LocationShowFilter = 'All'">
                      <CIcon name="cil-filter" />
                  </CButton>
                  <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="LocationFilterModel = true;LocationShowFilter = 'City'">{{ $t('System/Basic.LocationField.City') }}: {{ LocationSearchFilter.City || '--' }} <CIcon size="sm" class="ml-1" name="cil-pencil" /></CButton>
                  <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="LocationFilterModel = true;LocationShowFilter = 'Area'">{{ $t('System/Basic.LocationField.Area') }}: {{ LocationSearchFilter.Area || '--' }} <CIcon size="sm" class="ml-1" name="cil-pencil" /></CButton>
                  <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ChangeLocationFilter(true)"><CIcon size="sm" name="cil-x" /></CButton>
                  <CButton shape="pill" class="mr-2" @click="OpenLocationModel()" color="info">{{ $t('System/Basic.AddLocation') }}</CButton>
              </div>
              <CDataTable
                  id="LocationList"
                  :items="BasicData.Location"
                  :fields="LocationField"
                  :loading="Loading"
                  :noItemsView="noItemsView"
                  responsive
                  hover
              >
                <template #Status="{item}">
                  <td>
                    <CBadge :color="(item.Status === true ? 'success' : 'danger')">
                      {{ item.Status === true ? $t('Global.Display.Show') : $t('Global.Display.Hide') }}
                    </CBadge>
                  </td>
                </template>
                <template #Action="{index}">
                  <td>
                    <CButton color="info" size="sm" class="mr-1" @click="OpenLocationModel(index)">
                      <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/> {{ $t('Global.Edit') }}
                    </CButton>
                    <CButton color="danger" size="sm" @click="DeleteLocation(index)">
                      <CIcon name="cil-trash" class="c-icon-custom-size"/>
                    </CButton>
                  </td>
                </template>
              </CDataTable>
              <infinite-loading :identifier="LocationInfiniteId" v-show="BasicData.Location.length > 0" @infinite="GetLocationData">
                <template #no-more>
                  <CAlert color="secondary">
                    {{ $t('Global.NoMoreData') }}
                  </CAlert>
                </template>
                <template #no-results>
                  <CAlert color="secondary">
                    {{ $t('Global.NoData') }}
                  </CAlert>
                </template>
                <template #error>
                  <CAlert color="danger">
                    {{ $t('Global.ErrorLoaded') }}
                  </CAlert>
                </template>
                <template #spinner>
                  <CSpinner class="mt-2" grow color="dark" />
                </template>
              </infinite-loading>
              <div class="d-flex justify-content-between">
                <CButton @click="OpenLocationModel()" color="info">{{ $t('System/Basic.AddLocation') }}</CButton>
              </div>
            </CTab>
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
    <CModal id="LocationModel" class="ActionModel" :show.sync="LocationModel" size="lg" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Edit') + $t('System/Basic.Location') }}</h5>
          <CButtonClose @click="CloseLocationModel()"/>
        </header>
      </template>
      <div class="px-3">
        <CRow class="mt-3">
          <CCol lg="6">
            <CInput horizontal v-model="NowSelectLocation.Name" :label="$t('System/Basic.LocationField.Name')"/>
            <CSelect horizontal :label="$t('System/Basic.LocationField.Country')" :options="Country" v-model="NowSelectLocation.Country" :value.sync="NowSelectLocation.Country" :placeholder="$t('Global.PleaseSelect')"/>
            <CSelect horizontal :label="$t('System/Basic.LocationField.City')" :options="City" v-model="NowSelectLocation.City" :value.sync="NowSelectLocation.City" :placeholder="$t('Global.PleaseSelect')"/>
            <CSelect horizontal :label="$t('System/Basic.LocationField.Area')" :options="Area" v-model="NowSelectLocation.Area" :value.sync="NowSelectLocation.Area" @change="SetZip()" :placeholder="$t('Global.PleaseSelect')"/>
            <CInput horizontal type="text" :label="$t('Contact.Cover')" v-model="NowSelectLocation.Cover">
              <template #append>
                <CButton type="button" color="dark" @click="OpenMediaStore('NowSelectLocation', false, 'Cover')">{{ $t('Global.Browse') }}</CButton>
              </template>
            </CInput>
            <CInput horizontal v-model="NowSelectLocation.GoogleMapURL" :label="$t('System/Basic.LocationField.GoogleMapURL')"/>
          </CCol>
          <CCol lg="6">
            <CInput horizontal v-model="NowSelectLocation.PostNum" :label="$t('System/Basic.LocationField.PostNum')" disabled/>
            <CInput horizontal v-model="NowSelectLocation.Tel" :label="$t('System/Basic.LocationField.Tel')"/>
            <CInput horizontal v-model="NowSelectLocation.Email" :label="$t('System/Basic.LocationField.Email')"/>
            <CInput horizontal v-model="NowSelectLocation.Address" :label="$t('System/Basic.LocationField.Address')"/>
            <CRow form class="form-group">
              <CCol tag="label" sm="3" class="col-form-label">
                {{ $t('System/Basic.LocationField.Status') }}
              </CCol>
              <CCol sm="9">
                <CSwitch color="success" :checked.sync="NowSelectLocation.Status"/>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true) || (Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="CloseLocationModel" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="SaveLocation" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="LocationFilterModel" class="ActionModel" :show.sync="LocationFilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CSelect horizontal :label="$t('System/Basic.LocationField.City')" :options="City" v-model="LocationSearchFilter.City" :value.sync="LocationSearchFilter.City" :placeholder="$t('Global.PleaseSelect')"/>
      <CSelect v-if="LocationShowFilter === 'All' || LocationShowFilter === 'Area'" horizontal :label="$t('System/Basic.LocationField.Area')" :options="AreaByFilter" v-model="LocationSearchFilter.Area" :value.sync="LocationSearchFilter.Area" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="LocationFilterModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="LocationFilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangeLocationFilter()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages(ChooseImageType, ChooseImageIndex, ChooseImageItem)" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "基礎設定"
  }
}
</route>

<script>
import District from '@/assets/json/Districts.json'

export default {
  name: 'BasicSetting',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    InfiniteLoading: () => import('vue-infinite-loading'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
  },
  data () {
    return {
      Accordion: 0,
      BasicData: {
        Info: {},
        Contact: {},
        Social: [],
        Location: []
      },
      Loading: false,
      Submit: false,
      NowChooseLanguage: 'default',
      ChooseImageType: '',
      ChooseImageIndex: false,
      ChooseImageItem: '',
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      Features: [],
      SocialOptions: {
        Facebook: 'cib-facebook',
        Line: 'cib-line',
        Twitter: 'cib-twitter',
        Linkedin: 'cib-linkedin',
        Github: 'cib-github',
        Instagram: 'cib-instagram',
        Youtube: 'cib-youtube',
        Behance: 'cib-behance',
        Discord: 'cib-discord'
      },
      FieldName: '',
      NowSelectLocation: {},
      NowSelectLocationIndex: false,
      LocationSearchFilter: {
        City: '',
        Area: ''
      },
      LocationPage: 0,
      LocationInfiniteId: +new Date(),
      LocationShowFilter: 'All',
      LocationFilterModel: false,
      LocationModel: false,
      LocationField: [
        { key: 'PostNum', label: this.$t('System/Basic.LocationField.PostNum') },
        { key: 'Name', label: this.$t('System/Basic.LocationField.Name') },
        { key: 'City', label: this.$t('System/Basic.LocationField.City') },
        { key: 'Address', label: this.$t('System/Basic.LocationField.Address') },
        { key: 'Tel', label: this.$t('System/Basic.LocationField.Tel') },
        { key: 'Status', label: this.$t('System/Basic.LocationField.Status') },
        { key: 'Action', label: '' }
      ],
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      District
    }
  },
  computed: {
    Country () {
      return ['Taiwan']
    },
    City () {
      return this.District.map(Data => Data.name)
    },
    Area () {
      const ChooseCityData = this.District.filter(Data => Data.name === this.NowSelectLocation.City) || []
      if (ChooseCityData.length > 0) {
        return ChooseCityData[0].districts.map(Area => Area.name)
      } else {
        return []
      }
    },
    AreaByFilter () {
      const ChooseCityData = this.District.filter(Data => Data.name === this.LocationSearchFilter.City) || []
      if (ChooseCityData.length > 0) {
        return ChooseCityData[0].districts.map(Area => Area.name)
      } else {
        return []
      }
    },
    FilterSocialOptions () {
      const Result = {}
      const ExistData = this.BasicData.Social.map(Data => Data.Name)
      Object.keys(this.SocialOptions).forEach((SocialName) => {
        if (!ExistData.includes(SocialName)) {
          Result[SocialName] = this.SocialOptions[SocialName]
        }
      })
      return Result
    }
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Basic
      const LoadData = this.Features.map(type => {
        return this.GetData(type)
      })
      return Promise.all(LoadData).then(() => {
          if (this.$store.state.user.permission.MutipleLocale) {
              const LangSchema = ['Info', 'Contact']
              LangSchema.forEach((schema) => {
                  if (!this.BasicData[schema].LanguageData) {
                      this.$set(this.BasicData[schema], 'LanguageData', {})
                  }
                  this.$store.state.user.permission.Locales.forEach((locale) => {
                      if (!this.BasicData[schema].LanguageData[locale]) {
                          this.$set(this.BasicData[schema].LanguageData, locale, {})
                      }
                  })
              })
              this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
          }
      }).catch((err) => {
        throw err
      })
    },
    GetData(type) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/' + type,
        method: 'post'
      }).then(({data}) => {
        this.$set(this.BasicData, type, data)
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetLocationData($state) {
      const data = {}
      if (this.BasicData.Location.length > 0) {
        data.next = this.BasicData.Location[this.BasicData.Location.length - 1].PostNum
      }
      if (this.LocationSearchFilter.City !== '') {
        data.city = this.LocationSearchFilter.City
      }
      if (this.LocationSearchFilter.Area !== '') {
        data.area = this.LocationSearchFilter.Area
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/Location',
        method: 'post',
        data
      }).then(({data}) => {
        this.Loading = false
        if (data.length) {
          this.BasicData.Location.push(...data)
          if ($state) $state.loaded()
        } else {
          if ($state) $state.complete()
        }
        return true
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    ChangeLocationFilter(reset = false) {
      if (reset) {
        this.LocationSearchFilter = {
          City: '',
          Area: ''
        }
      }
      this.Loading = true
      this.LocationInfiniteId += 1
      this.LocationFilterModel = false
      this.BasicData.Location = []
      this.GetLocationData()
    },
    SetData(type) {
      const data = {
        [type]: this.BasicData[type]
      }
      if (data.Info && data.Info.Domain) {
        data.Info.Domain = data.Info.Domain.replace('https://', '').replace('http://', '').replace('/', '')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/' + type,
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    AddData(type) {
      if (!this.BasicData[type]) this.$set(this.BasicData, type, [])
      let Data
      switch (type) {
        case 'ServiceLocation':
          Data = {
            Name: '[' + this.$t('System/Themes.ServiceLocationTitle') + ']',
            Address: '',
            BusinessHours: '',
            TEL: '',
            FAX: '',
            GoogleMapURL: '',
            LanguageData: {}
          }
          this.$store.state.user.permission.Locales.forEach((locale) => {
            Data.LanguageData[locale] = {
              Name: '[' + this.$t('System/Themes.ServiceLocationTitle') + ']',
              Address: '',
              BusinessHours: '',
              TEL: '',
              FAX: '',
              GoogleMapURL: '',
            }
          })
          break
        case 'Social':
          Data = {
            Link: '',
            Name: 'Facebook'
          }
          break
      }
      this.BasicData[type].push(Data)
    },
    RemoveData(type, index) {
      this.BasicData[type].splice(index, 1)
    },
    OpenLocationModel(index = false) {
      if (index !== false) {
        this.NowSelectLocation = this.BasicData.Location[index]
        this.NowSelectLocationIndex = index
      } else {
        this.NowSelectLocation = {
          Country: 'Taiwan'
        }
      }
      this.LocationModel = true
    },
    CloseLocationModel() {
      this.NowSelectLocation = {
        Country: 'Taiwan'
      }
      this.NowSelectLocationIndex = null
      this.LocationModel = false
    },
    SetZip () {
      this.NowSelectLocation.PostNum = parseInt(this.District.filter(Data => Data.name === this.NowSelectLocation.City)[0].districts.filter(Area => Area.name === this.NowSelectLocation.Area)[0].zip)
    },
    SaveLocation() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/Location',
        method: 'post',
        data: {
          DocID: this.NowSelectLocation.DocID || null,
          Location: this.NowSelectLocation
        }
      }).then(() => {
        this.Loading = false
        this.LocationModel = false
        this.GetData('Location')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    DeleteLocation(index) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/delete/Location',
        method: 'post',
        data: {
          DocID: this.BasicData.Location[index].DocID
        }
      }).then(() => {
        this.Loading = false
        this.LocationModel = false
        this.GetData('Location')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DeleteFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    //圖片處理
    OpenMediaStore(type = 'Carousel', multiple = true, index = false, item = 'Cover') {
      this.ChooseImageType = type
      this.ChooseImageItem = item
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    SetImages (type = 'ServiceLocation', index, item = '') {
      switch (type) {
        case 'ServiceLocation':
        case 'Info':
          this.SetSingleImage(type, index, item)
          break
        case 'NowSelectLocation':
          this.SetSingleImage2(type, item)
          break
      }
    },
    SetSingleImage(type, index, item) {
      if (this.$store.state.user.permission.StaticDomain) {
          if (this.NowChooseLanguage === 'default') {
              if (index) this.$set(this.BasicData[type][index], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
              else this.$set(this.BasicData[type], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
          } else {
              if (index) this.$set(this.BasicData[type][index].LanguageData[this.NowChooseLanguage], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
              else this.$set(this.BasicData[type].LanguageData[this.NowChooseLanguage], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
          }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
            if (this.NowChooseLanguage === 'default') {
                if (index) this.$set(this.BasicData[type][index], item, URL)
                else this.$set(this.BasicData[type], item, URL)
            } else {
                if (index) this.$set(this.BasicData[type][index].LanguageData[this.NowChooseLanguage], item, URL)
                else this.$set(this.BasicData[type].LanguageData[this.NowChooseLanguage], item, URL)
            }
        })
      }
    },
    SetSingleImage2(type, item) {
      if (this.$store.state.user.permission.StaticDomain) {
        this.$set(this[type], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          this.$set(this[type], item, URL)
        })
      }
    }
  }
}
</script>

<style scoped>
.favicon {
  width: 64px;
  height: 64px;
}
.CarouselItem {
  min-height: initial;
}
</style>
